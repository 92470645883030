import { Outlet, useLocation } from 'react-router-dom';
import Header from '../header/header';
import Footer from '../footer/footer';
import Paths from '../../router/paths';
import SecondaryFooter from '../footer/secondary-footer';

const MainLayout = () => {
  const navigate = useLocation();
  return (
    <>
      <div className="main-wrapper">
        <Header />
        <Outlet />
        {navigate.pathname === Paths.generateImage ? '' : <SecondaryFooter />}
      </div>
    </>
  );
};

export default MainLayout;
