import React from 'react';
import { GlobalProvider } from './contexts/GlobalState';
import { GlobalModalProvider } from './contexts/GlobalModalContext';
import Router from './router';
import { BrowserRouter } from 'react-router-dom';
import ImageHistoryProvider from './contexts/ImageHistory';
import LoaderProvider from './contexts/LoaderContext';
import CustomLoader from './components/CustomLoader';
import CustomToaster from './components/custom-toaster/custom-toaster';
import NewUiProvider from './contexts/feature-flag-context';
import { DownloadProvider } from './contexts/download-file-context';
import DownloadingNotification from './components/downloading-notification/downloading-notification';

function App() {
  return (
    <div className="App">
      <GlobalProvider>
        <GlobalModalProvider>
          <BrowserRouter>
            <ImageHistoryProvider>
              <DownloadProvider>
                <LoaderProvider>
                  <NewUiProvider>
                    <Router />
                  </NewUiProvider>
                  <CustomLoader />
                  <CustomToaster />
                  <DownloadingNotification />
                </LoaderProvider>
              </DownloadProvider>
            </ImageHistoryProvider>
          </BrowserRouter>
        </GlobalModalProvider>
      </GlobalProvider>
    </div>
  );
}

export default App;
