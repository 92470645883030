import { createContext, useContext, useState } from 'react';
import {
  removeBackgroundImage,
  upscaleImage,
} from '../services/generalService';
import { convertToDataURL, downloadImage } from '../utils/basic-common';
import { GlobalContext } from './GlobalState';
import { pushActivity } from '../services/images-service';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const DownloadContext = createContext();

export const DownloadProvider = ({ children }) => {
  const { profile } = useContext(GlobalContext);
  const [downloading, setDownloading] = useState(false);

  const downloadFile = async ({
    imageDetails,
    downloadSize,
    isTransparent,
    isFormatPng,
  }) => {
    try {
      setDownloading(true);
      // Simulated asynchronous download
      await downloadFileAsync({
        imageDetails,
        downloadSize,
        isTransparent,
        isFormatPng,
      });
    } catch (error) {
      setDownloading(false);
    }
  };
  const downloadFileAsync = async ({
    imageDetails,
    downloadSize,
    isTransparent,
    isFormatPng,
  }) => {
    const a = document.createElement('a');
    if (
      profile?.profile_type_name === 'Visionary' ||
      (profile?.profile_type_name === 'Explorer' &&
        profile?.packs_used < profile?.packs)
    ) {
      if (
        downloadSize === '1x' &&
        isTransparent === 'no' &&
        isFormatPng === 'jpg'
      ) {
        await downloadImage(
          imageDetails?.image_url,
          imageDetails?._id + '.' + isFormatPng
        );
        setDownloading(false);
      } else {
        const dataUrl = await convertToDataURL(imageDetails?.image_url);
        // console.log(dataUrl);
        let upscaleDetails = {
          scale: downloadSize.replace('x', ''),
          image: dataUrl,
        };
        // console.log(upscaleDetails);
        let upscaledUrl = '';
        if (downloadSize !== '1x') {
          // console.log(upscaleDetails);
          const res = await upscaleImage(upscaleDetails);
          if (res.data.status) {
            upscaledUrl = res.data.data;
          }
          upscaleDetails.image = res.data.data;
        }
        if (isTransparent != 'no') {
          upscaledUrl = await convertToDataURL(upscaledUrl);
          // console.log(upscaleDetails.image);
          const res = await removeBackgroundImage({
            image: upscaledUrl,
          });
          if (res.data.status) {
            a.href = res.data.data;
          }
          upscaleDetails.image = res.data.data;
        }
        await downloadImage(
          upscaleDetails?.image,
          imageDetails?._id + '.' + isFormatPng
        );
        // a.href = upscaleDetails?.image;
        // a.download = imageDetails?._id + '.' + isFormatPng;
        setDownloading(false);
      }
    } else {
      if (
        profile?.profile_type_name === 'Explorer' &&
        profile?.packs_used < profile?.packs
      ) {
        toast.error('Download limit exceeded');
      }
      setDownloading(false);
    }
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    addToDownloadList(imageDetails?._id);
  };

  const addToDownloadList = async (id) => {
    try {
      const payload = {
        image_id: id,
        activity_type: 'image_download',
      };
      const res = await pushActivity(payload);
      if (!res.data.status) {
        throw Error(res.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <DownloadContext.Provider value={{ downloading, downloadFile }}>
        {children}
      </DownloadContext.Provider>
    </>
  );
};

export const useDownload = () => useContext(DownloadContext);
