import React from 'react';
import { Link } from 'react-router-dom';
import Paths from '../../../router/paths';

const CreateImageBtn = (props) => {
  const { navigateFromSideMenu } = props;

  return (
    <div className="header-link">
      <button
        className="btn btn-primary"
        onClick={() => navigateFromSideMenu('generateImage')}
      >
        Create
      </button>
    </div>
  );
};
export default CreateImageBtn;
