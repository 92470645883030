import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Paths from '../router/paths';

const GuestGuard = ({ children }) => {
  const cookie = Cookies.get('access_token');
  if (cookie?.length) {
    return <Navigate to={Paths.root} replace />;
  }

  return children;
};

export default GuestGuard;
