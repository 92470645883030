import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCopy, faSms } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { Modal } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export const ReferralModal = ({
  referralCode,
  handleClose,
  isReferralModalOpen,
}) => {
  const { t } = useTranslation();
  const sharingURL = `https://app.cascaid.io/sign-up?referralCode=${referralCode}`;
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Modal
      open={isReferralModalOpen}
      onClose={handleClose}
      style={styles.modal}
    >
      <div style={styles.container}>
        <div style={styles.closeIconContainer} onClick={handleClose}>
          <FontAwesomeIcon icon={faClose} size="xl" />
        </div>
        <h3 style={styles.titleText}>Refer A Friend</h3>
        <h3 style={styles.bannerText} className="text-center">
          {t('generateImage.earn')} <span style={styles.creditSpan}>250</span>{' '}
          {t('generateImage.tokensForYouAndYourFriend')}
        </h3>
        <CopyToClipboard text={sharingURL} onCopy={() => setIsCopied(true)}>
          <div style={styles.clipboardContainer}>
            <p style={styles.clipboardLink}>
              https://app.cascaid.io/sign-up...
            </p>
            <div style={styles.clipboardButton}>
              <FontAwesomeIcon icon={faCopy} />
            </div>
          </div>
        </CopyToClipboard>
        {isCopied && (
          <p style={styles.successfulCopyText}>Copied to Clipboard!</p>
        )}
        <div
          style={{
            ...styles.socialIconContainer,
            marginTop: isCopied ? '0px' : '30px',
          }}
        >
          <FacebookShareButton url={sharingURL} hashtag="#cascaid">
            <div style={styles.socialIcon}>
              <FontAwesomeIcon icon={faFacebook} size="xl" />
            </div>
          </FacebookShareButton>
          <LinkedinShareButton url={sharingURL} hashtag="#cascaid">
            <div style={styles.socialIcon}>
              <FontAwesomeIcon icon={faLinkedinIn} size="xl" />
            </div>
          </LinkedinShareButton>
          <TwitterShareButton url={sharingURL} hashtag="#cascaid">
            <div style={styles.socialIcon}>
              <FontAwesomeIcon icon={faTwitter} size="xl" />
            </div>
          </TwitterShareButton>
          {isMobile && (
            <div style={styles.socialIcon}>
              <a href={`sms:?&body=${sharingURL}`} style={styles.link}>
                <FontAwesomeIcon icon={faSms} size="xl" />
              </a>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const styles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '90%',
    maxWidth: '480px',
    height: '400px',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: 'rgba(0,0,0,.2)',
    borderRadius: '5px',
    position: 'relative',
    outline: 0,
  },
  closeIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '60px',
    width: '60px',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.2,
    cursor: 'pointer',
  },
  titleText: {
    fontWeight: '600',
    fontSize: '28px',
  },
  bannerText: {
    fontWeight: '600',
    fontSize: '38px',
  },
  creditSpan: { color: '#6EE2FF' },
  clipboardContainer: {
    width: '80%',
    height: '50px',
    marginTop: '20px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid',
    borderColor: 'rgba(0,0,0,.2)',
    cursor: 'pointer',
  },
  clipboardLink: { margin: 0, padding: 0, paddingLeft: '30px' },
  clipboardButton: {
    height: '100%',
    width: '80px',
    zIndex: 10,
    backgroundColor: '#6EE2FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    borderBottomRightRadius: '20px',
    borderTopRightRadius: '20px',
  },
  successfulCopyText: { margin: '15px 0px', padding: 0 },
  socialIconContainer: { display: 'flex' },
  socialIcon: {
    height: '60px',
    width: '60px',
    backgroundColor: '#6EE2FF',
    margin: '0px 7.5px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    color: '#000',
  },
};
