import { createContext, useContext, useState } from "react";

const GlobalModalContext = createContext();

export const useGlobalModals = () => {
  return useContext(GlobalModalContext);
};

export const GlobalModalProvider = ({ children }) => {
  const [visibleModals, setVisibleModals] = useState({
    referralModalVisible: true,
  });

  const value = { visibleModals, setVisibleModals };

  return (
    <GlobalModalContext.Provider value={value}>
      {children}
    </GlobalModalContext.Provider>
  );
};
