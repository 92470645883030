import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en.json';
import krTranslation from './locales/kr.json';

i18n
  .use(LanguageDetector) // Initialize language detector
  .use(initReactI18next) // Bind i18next to React
  .init({
    resources: {
      en: {
        translation: enTranslation, // English translations
      },
      kr: {
        translation: krTranslation, // French translations
      },
    },
    fallbackLng: 'en', // Fallback language if the selected one is not available
    debug: true, // Enable debug mode for development
    lng: 'en', // default language
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
