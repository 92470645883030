import React, { createContext, useContext, useEffect, useState } from 'react';

const LoaderContext = createContext({});

const LoaderProvider = (props) => {
  const { children } = props;
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(loader);
  }, [loader]);

  const startLoader = () => {
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };

  return (
    <>
      <LoaderContext.Provider value={{ loader, startLoader, closeLoader }}>
        {children}
      </LoaderContext.Provider>
    </>
  );
};

export default LoaderProvider;

export const useLoader = () => {
  return useContext(LoaderContext);
};
