import React, { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Paths from './paths';
import GuestGuard from '../guards/GuestGuard';
import MainLayout from '../components/main-layout/main-layout';
import PrivateGuard from '../guards/PrivateGuard';
import Aistock from '../pages/aistock/aistock';
import SecondaryLayout from '../components/main-layout/secondary-layout';
import { useNewUi } from '../contexts/feature-flag-context';

const Loadable = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <div className="custom-spinner fixed-top">
          <div className="spinner-border" role="status"></div>
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const CM_MAP = {
  HomePage: {
    New: Loadable(lazy(() => import('../pages/aistock/aistock'))),
    Old: Loadable(lazy(() => import('../pages/home/home'))),
  },
};

const Router = () => {
  const { newUi } = useNewUi();

  const SignInPage = Loadable(lazy(() => import('../pages/sign-in/sign-in')));
  const HomePage = CM_MAP.HomePage[newUi ? 'New' : 'Old'];
  const SignUpPage = Loadable(lazy(() => import('../pages/sign-up/sign-up')));
  const ResetPasswordPage = Loadable(
    lazy(() => import('../pages/reset-password/reset-password'))
  );
  const ForgotPasswordPage = Loadable(
    lazy(() => import('../pages/forgot-password/forgot-password'))
  );
  const DiscoverPage = Loadable(
    lazy(() => import('../pages/discover/discover'))
  );
  const PricingPage = Loadable(lazy(() => import('../pages/pricing/pricing')));
  const CollectionPage = Loadable(
    lazy(() => import('../pages/collections/collections'))
  );
  const CollectionItemPage = Loadable(
    lazy(() => import('../pages/collections/collection-item'))
  );
  const SubscriptionCanceledPage = Loadable(
    lazy(() =>
      import(
        '../pages/subscription-canceled-confirm/subscription-canceled-confirm'
      )
    )
  );
  const ProfilePage = Loadable(
    lazy(() => import('../pages/user-profile/user-profile'))
  );
  const AccountPage = Loadable(
    lazy(() => import('../pages/user-account/user-account'))
  );
  const MyAccountPage = Loadable(
    lazy(() => import('../pages/account/account'))
  );
  const GenerateImagePage = Loadable(
    lazy(() => import('../pages/generate-image/generate-image'))
  );
  const ImagesPage = Loadable(lazy(() => import('../pages/images/images')));
  const SubscriptionPage = Loadable(
    lazy(() => import('../pages/subscription/subscription'))
  );
  const MonthlyPackagePage = Loadable(
    lazy(() => import('../pages/monthly-package/monthly-package'))
  );
  const CancelSubsSuccessPage = Loadable(
    lazy(() =>
      import(
        '../pages/cancel-subscription-successful/cancel-subscription-successful'
      )
    )
  );
  const PaymentSuccessPage = Loadable(
    lazy(() => import('../pages/payment-success/payment-success'))
  );
  const PaymentFailurePage = Loadable(
    lazy(() => import('../pages/payment-failure/payment-failure'))
  );
  const UnsubscribePage = Loadable(
    lazy(() => import('../pages/unsubscribe/unsubscribe'))
  );
  const UpgradePackagePage = Loadable(
    lazy(() => import('../pages/upgrade-package/upgrade-package'))
  );
  const TermsofUsePage = Loadable(
    lazy(() => import('../pages/terms-of-use/terms-of-use'))
  );
  const PrivacyPolicyPage = Loadable(
    lazy(() => import('../pages/privacy-policy/privacy-policy'))
  );
  const AiStockPage = Loadable(lazy(() => import('../pages/aistock/aistock')));
  const ErrorPage = Loadable(lazy(() => import('../pages/404/404')));
  const ContactUs = Loadable(
    lazy(() => import('../pages/contact-us/contact-us'))
  );
  const RecentSearches = Loadable(
    lazy(() => import('../pages/recent-activities/recent-activities'))
  );
  const FileUpload = Loadable(
    lazy(() => import('../pages/file-upload/file-upload'))
  );
  const SearchResult = Loadable(
    lazy(() => import('../pages/search-result/search-result'))
  );

  return useRoutes([
    {
      path: Paths.root,
      element: newUi ? <SecondaryLayout /> : <MainLayout />,
      children: [
        {
          index: true,
          element: <HomePage />,
        },
      ],
    },
    {
      path: Paths.signIn,

      element: (
        <GuestGuard>
          <SecondaryLayout />
        </GuestGuard>
      ),
      children: [
        {
          index: true,
          element: <SignInPage />,
        },
      ],
    },
    {
      path: Paths.signUp,
      element: (
        <GuestGuard>
          <SecondaryLayout />
        </GuestGuard>
      ),
      children: [
        {
          index: true,
          element: <SignUpPage />,
        },
      ],
    },
    {
      path: Paths.resetPassword,
      element: newUi ? <SecondaryLayout /> : <MainLayout />,
      children: [
        {
          index: true,
          element: <ResetPasswordPage />,
        },
      ],
    },
    {
      path: Paths.forgotPassword,
      element: newUi ? <SecondaryLayout /> : <MainLayout />,
      children: [
        {
          index: true,
          element: <ForgotPasswordPage />,
        },
      ],
    },
    {
      path: Paths.discover,
      element: newUi ? <SecondaryLayout /> : <MainLayout />,
      children: [
        {
          index: true,
          element: <DiscoverPage />,
        },
      ],
    },
    {
      path: Paths.exploreItem,
      element: newUi ? <SecondaryLayout /> : <MainLayout />,
      children: [
        {
          index: true,
          element: <DiscoverPage />,
        },
      ],
    },
    {
      path: Paths.pricing,
      element: newUi ? <SecondaryLayout /> : <MainLayout />,
      children: [
        {
          index: true,
          element: <PricingPage />,
        },
      ],
    },
    {
      path: Paths.monthlyPackage,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <MonthlyPackagePage />,
        },
      ],
    },
    {
      path: Paths.subscription,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <SubscriptionPage />,
        },
      ],
    },
    {
      path: Paths.cancelSubscriptionSuccess,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <CancelSubsSuccessPage />,
        },
      ],
    },
    {
      path: Paths.userProfile,
      element: (
        <PrivateGuard>
          {newUi ? <SecondaryLayout /> : <MainLayout />}
        </PrivateGuard>
      ),
      children: [
        {
          index: true,
          element: <ProfilePage />,
        },
      ],
    },
    {
      path: Paths.collections,
      element: (
        <PrivateGuard>
          {newUi ? <SecondaryLayout /> : <MainLayout />}
        </PrivateGuard>
      ),
      children: [
        {
          index: true,
          element: <CollectionPage />,
        },
      ],
    },
    {
      path: Paths.collectionItem,
      element: (
        <PrivateGuard>
          {newUi ? <SecondaryLayout /> : <MainLayout />}
        </PrivateGuard>
      ),
      children: [
        {
          index: true,
          element: <CollectionItemPage />,
        },
      ],
    },
    {
      path: Paths.userAccount,
      element: (
        <PrivateGuard>
          <MainLayout />
        </PrivateGuard>
      ),
      children: [
        {
          index: true,
          element: <AccountPage />,
        },
      ],
    },
    {
      path: Paths.account,
      element: (
        <PrivateGuard>
          {newUi ? <SecondaryLayout /> : <MainLayout />}
        </PrivateGuard>
      ),
      children: [
        {
          index: true,
          element: <MyAccountPage />,
        },
      ],
    },
    {
      path: Paths.generateImage,
      element: (
        <PrivateGuard>
          <MainLayout />
        </PrivateGuard>
      ),
      children: [
        {
          index: true,
          element: <GenerateImagePage />,
        },
      ],
    },
    {
      path: Paths.singleImage,
      element: <SecondaryLayout />,
      children: [
        {
          index: true,
          element: <ImagesPage />,
        },
      ],
    },

    {
      path: Paths.subscriptionCanceledConfirm,
      element: (
        <PrivateGuard>
          <MainLayout />
        </PrivateGuard>
      ),
      children: [
        {
          index: true,
          element: <SubscriptionCanceledPage />,
        },
      ],
    },
    {
      path: Paths.paymentSuccess,
      element: (
        <PrivateGuard>
          {newUi ? <SecondaryLayout /> : <MainLayout />}
        </PrivateGuard>
      ),
      children: [
        {
          index: true,
          element: <PaymentSuccessPage />,
        },
      ],
    },
    {
      path: Paths.paymentFailure,
      element: (
        <PrivateGuard>
          {newUi ? <SecondaryLayout /> : <MainLayout />}
        </PrivateGuard>
      ),
      children: [
        {
          index: true,
          element: <PaymentFailurePage />,
        },
      ],
    },
    {
      path: Paths.unsubscribe,
      element: (
        <PrivateGuard>
          <MainLayout />
        </PrivateGuard>
      ),
      children: [
        {
          index: true,
          element: <UnsubscribePage />,
        },
      ],
    },
    {
      path: Paths.upgradePackage,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <UpgradePackagePage />,
        },
      ],
    },
    {
      path: Paths.aistock,
      element: <SecondaryLayout />,
      children: [
        {
          index: true,
          element: <AiStockPage />,
        },
      ],
    },
    {
      path: Paths.privacyPolicy,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <PrivacyPolicyPage />,
        },
      ],
    },
    {
      path: Paths.termsOfUse,
      element: <SecondaryLayout />,
      children: [
        {
          index: true,
          element: <TermsofUsePage />,
        },
      ],
    },
    {
      path: Paths.contactUs,
      element: <SecondaryLayout />,
      children: [
        {
          index: true,
          element: <ContactUs />,
        },
      ],
    },
    {
      path: Paths.recentSearches,
      element: <SecondaryLayout />,
      children: [
        {
          index: true,
          element: <RecentSearches />,
        },
      ],
    },
    ,
    {
      path: Paths.fileUpload,
      element: newUi ? <SecondaryLayout /> : <MainLayout />,
      children: [
        {
          index: true,
          element: <FileUpload />,
        },
      ],
    },

    {
      path: Paths.searchResult,
      element: newUi ? <SecondaryLayout /> : <MainLayout />,
      children: [
        {
          index: true,
          element: <SearchResult />,
        },
      ],
    },

    {
      path: Paths.rest,
      element: <SecondaryLayout />,
      children: [
        {
          index: true,
          element: <ErrorPage />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to={Paths.rest} />,
    },
  ]);
};

export default Router;
