import React, { createContext, useContext, useEffect, useState } from 'react';

const NewUiContext = createContext();

const NewUiProvider = (props) => {
  const { children } = props;
  const [newUi, setNewUi] = useState(true);

  useEffect(() => {
    setNewUi(newUi);
  }, [newUi]);

  return (
    <>
      <NewUiContext.Provider value={{ newUi }}>
        {children}
      </NewUiContext.Provider>
    </>
  );
};

export default NewUiProvider;

export const useNewUi = () => {
  return useContext(NewUiContext);
};
