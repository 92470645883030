import {useLoader} from "../../contexts/LoaderContext";

const CustomLoader = () => {
    const {loader} = useLoader();
    return (
        <>
            { loader && (
                <div className="custom-spinner fixed-top">
                    <div className="spinner-border" role="status"></div>
                </div>
            )}</>
    )
}

export default CustomLoader;