import React, { Fragment, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate } from 'react-router-dom';
import Paths from '../../../router/paths';
import { fetchImageStyles } from '../../../services/images-service';

const HeaderSearch = (props) => {
  const { searchToggle } = props;
  const navigate = useNavigate();

  const [searchData, setSearchData] = useState('');
  const [stylesList, setStylesList] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState({});
  useEffect(() => {
    (async function () {
      try {
        const res = await fetchImageStyles();
        if (!res.data.status) {
          throw res.data.message;
        }
        // console.log(res.data.data);
        setStylesList(res.data.data);
        setSelectedStyle(res.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  const handleRedirection = (e) => {
    if (e.key === 'Enter') {
      console.log(selectedStyle?.id);
      navigate(
        `${Paths.discover}?search=${searchData}&selectedId=${selectedStyle?.id}`
      );
      // window.location.reload();
    }
  };
  return (
    <Fragment>
      <div className={`header-search-wrap ${searchToggle ? 'active' : ''}`}>
        <div className="search-left">
          <Dropdown className="link-dropdown2">
            <Dropdown.Toggle id="dropdown-basic" className="text-truncate">
              <span className="icon-game heading14 me-1"></span>
              <span className="d-xl-inline-block d-none">
                {' '}
                {selectedStyle?.style === 'Photorealism'
                  ? 'All Styles'
                  : selectedStyle?.style}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {stylesList.map((item, index) => (
                <Dropdown.Item
                  as="button"
                  key={index}
                  onClick={() => setSelectedStyle(item)}
                >
                  {item.style}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="search-right">
          <input
            className="form-control"
            type="text"
            value={searchData}
            onChange={(e) => setSearchData(e.target.value)}
            onKeyPress={handleRedirection}
            placeholder="Search..."
          />
          <Link
            to={`${Paths.discover}?search=${searchData}&selectedId=${selectedStyle?.id}`}
            className="btn icon-btn p-0"
          >
            <span className="icon-search-alt"></span>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};
export default HeaderSearch;
