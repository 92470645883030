import { createContext, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Paths from '../router/paths';

const ImageHistoryContext = createContext(null);

const ImageHistoryProvider = ({ children }) => {
  const { pathname } = useLocation();
  const imageHistoryRef = useRef([]);

  useEffect(() => {
    if (pathname !== Paths.generateImage) {
      if (imageHistoryRef?.current.length > 12) {
        imageHistoryRef.current = imageHistoryRef.current.slice(0, 12);
      }
      localStorage.setItem(
        'savedImagesHistory',
        JSON.stringify(imageHistoryRef.current)
      );
    } else {
      imageHistoryRef.current = JSON.parse(
        localStorage.getItem('savedImagesHistory')
      );
    }
  }, [pathname]);

  const pushImage = (images) => {
    imageHistoryRef?.current?.unshift(images);
  };
  const getImage = () => {
    return imageHistoryRef?.current;
  };
  const addImages = (images) => {
    imageHistoryRef.current = images;
  };

  const getPathname = () => {
    return pathname;
  };

  return (
    <ImageHistoryContext.Provider
      value={{ pushImage, getImage, addImages, getPathname }}
    >
      {children}
    </ImageHistoryContext.Provider>
  );
};

export const useImageHistory = () => {
  return useContext(ImageHistoryContext);
};

export default ImageHistoryProvider;
