import axios from 'axios';
import Cookies from 'js-cookie';

var headers = { 'Content-Type': 'multi-part/form-data' };
var config = { headers };
var access_token = Cookies.get('access_token') || '';

export const fetchImageDetails = (payload) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'get-image';
  for (const payloadKey in payload) {
    data.append(payloadKey, payload[payloadKey]);
  }
  return axios.post(url, data, config);
};

export const fetchSimilarImages = (payload) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'images/search/similar';
  data.append('access_token', access_token);
  for (const payloadKey in payload) {
    data.append(payloadKey, payload[payloadKey]);
  }
  return axios.post(url, data, config);
};

export const fetchSimilarImagesText = (payload) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'search-result/search/similar-text';
  data.append('access_token', access_token);
  for (const payloadKey in payload) {
    data.append(payloadKey, payload[payloadKey]);
  }
  return axios.post(url, data, config);
};

export const searchImages = (payload) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'images/search/similar';
  for (const payloadKey in payload) {
    data.append(payloadKey, payload[payloadKey]);
  }
  return axios.post(url, data, config);
};

export const fetchDiscoverImages = () => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'get-all-images';
  return axios.post(url, data, config);
};

export const pushActivity = (payload) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'activities/push';
  data.append('access_token', access_token);
  for (const payloadKey in payload) {
    data.append(payloadKey, payload[payloadKey]);
  }
  return axios.post(url, data, config);
};

export const fetchImageStyles = () => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'styles/list';
  data.append('access_token', access_token);
  return axios.post(url, data, config);
};

export const isFeatureEnable = (payload) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'feature-flags/check';
  data.append('access_token', access_token);
  for (const payloadKey in payload) {
    data.append(payloadKey, payload[payloadKey]);
  }
  return axios.post(url, data, config);
};
export const fetchSemanticImages = (payload) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'images/search/semantic';
  data.append('access_token', access_token);
  for (const payloadKey in payload) {
    data.append(payloadKey, payload[payloadKey]);
  }
  return axios.post(url, data, config);
};
