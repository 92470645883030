import React, { Fragment } from 'react';
import { useDownload } from '../../contexts/download-file-context';

const DownloadingNotification = () => {
  const { downloading } = useDownload();

  return (
    downloading && (
      <Fragment>
        <div
          className={`downloading-notification ${downloading ? 'active' : ''}`}
        >
          <span className="icon-download"></span>
          <span>Downloading...</span>
          <div className="spinner-border" role="status"></div>
        </div>
      </Fragment>
    )
  );
};
export default DownloadingNotification;
