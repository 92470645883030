import React, { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Paths from '../../../router/paths';
import { GlobalContext } from '../../../contexts/GlobalState';
import { handleLogout } from '../../../utils/basic-common';

const ProfileMenu = () => {
  const { profile } = useContext(GlobalContext);
  return (
    <div className="header-link">
      <Dropdown className="link-dropdown2 no-lines">
        <Dropdown.Toggle id="dropdown-basic1">
          <img
            src={'https://ui-avatars.com/api/?name=' + profile.username}
            alt="profile-pic"
            className="fit-cover"
          />
          {profile?.username}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {/* <p className="tokens-count">
            <span className="text-primary">{profile?.current_daily_count}</span>{' '}
            Tokens
          </p> */}
          <Dropdown.Item href={Paths.collections}>My Collections</Dropdown.Item>
          <Dropdown.Item href={`${Paths.account}?tab=downloads`}>
            My Downloads
          </Dropdown.Item>
          <Dropdown.Item href={Paths.account}>My Account</Dropdown.Item>
          <hr className="seperator m-0"></hr>
          <Dropdown.Item href="#">Help Center</Dropdown.Item>
          <Dropdown.Item as="button" onClick={handleLogout}>
            Log Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default ProfileMenu;
