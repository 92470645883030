import React, { Fragment, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import NoActivity from '../../components/no-activity/no-activity';
import { Link } from 'react-router-dom';
import Paths from '../../router/paths';
import DiscoverItem from '../../components/discover-item/discover-item';
import { userService } from '../../services';

const ActivitiesTabs = () => {
  const [recentActivityList, setRecentActivityList] = useState([]);
  const [downloadsList, setDownloadsList] = useState([]);

  useEffect(() => {
    getRecentActivity();
    getDownloads();
  }, []);

  const getRecentActivity = async () => {
    try {
      const res = await userService.fetchUserActivities();
      if (res.data.status) {
        const recentActivities = res.data.data
          .filter((activity) => activity.image && activity.image.image_url)
          .slice(0, 8);
        setRecentActivityList(recentActivities);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDownloads = async () => {
    try {
      // const res = await
      const payload = {
        activity_type: 'image_download',
      };
      const res = await userService.fetchUserActivities(payload);
      if (res.data.status) {
        // console.log(res.data.data);
        setDownloadsList(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const RedirectToDiscover = () => {
    return (
      <Link className="text-dark" to={Paths.discover}>
        Discover
      </Link>
    );
  };
  // const RedirectToRecent = () => {
  //   return (
  //     <Link className="text-dark" to={Paths.recentSearches}>
  //       Recent Activity
  //     </Link>
  //   );
  // };
  return (
    <Fragment>
      <div className="dark-tabs">
        <Tabs
          defaultActiveKey="RecentActivity"
          id="RecentActivityTabs"
          className=""
        >
          <Tab eventKey="RecentActivity" title="Recent Activity">
            <DiscoverItem
              data={recentActivityList}
              emptyListComp={
                <NoActivity
                  title="You don't have anything yet"
                  icon="icon-recent"
                />
              }
              title="Your recent activities"
              linkText="View All"
              linkUrl={Paths.recentSearches}
            />
          </Tab>
          <Tab title={<RedirectToDiscover />}></Tab>
          <Tab eventKey="Downloads" title="Downloads">
            <DiscoverItem
              data={downloadsList}
              emptyListComp={
                <NoActivity
                  title="You haven’t downloaded anything yet"
                  icon="icon-file-download"
                />
              }
              title="Your downloads"
              linkText="View All"
              linkUrl={Paths.discover}
            />
          </Tab>
        </Tabs>
      </div>
    </Fragment>
  );
};
export default ActivitiesTabs;
