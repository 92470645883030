import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/master.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import {transitions, positions, Provider as AlertProvider} from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import {GoogleOAuthProvider} from '@react-oauth/google';
import ReactGA from "react-ga4";
import "./translation/i18n";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)

const root = ReactDOM.createRoot(document.getElementById('root'));
const options = {
    position: positions.BOTTOM_RIGHT,
    timeout: 6000,
    offset: '10px',
    transition: transitions.SCALE
}
root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AlertProvider template={AlertTemplate} {...options}>
            <App/>
        </AlertProvider>
    </GoogleOAuthProvider>
);
reportWebVitals()
