import { SendApiRequest } from '../global/helpers';
import axios from 'axios';
import Cookies from 'js-cookie';

var headers = { 'Content-Type': 'multi-part/form-data' };
var config = { headers };
var access_token = Cookies.get('access_token') || '';

export const fetchSocialLinks = () => {
  return SendApiRequest('get-social-links', {});
};

export const fetchMembershipHistory = () => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'ledger';
  data.append('access_token', access_token);
  return axios.post(url, data, config);
};

export const fetchPaymentLink = (type, packs, interval) => {
  var url = process.env.REACT_APP_API_URL + 'get-payment-link';
  var data = new FormData();
  data.append('access_token', access_token);
  data.append('package', type);
  data.append('packs', packs);
  data.append('interval', interval);
  return axios.post(url, data, config);
};

export const cancelSubscription = () => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'cancel-subscription';
  data.append('access_token', access_token);
  return axios.post(url, data, config);
};
export const fetchMemberDetails = () => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'get-membership-details';
  data.append('access_token', access_token);
  return axios.post(url, data, config);
};

export const fetchStylesExamples = (id) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'styles/get-example-image';
  data.append('access_token', access_token);
  data.append('style_id', id);
  return axios.post(url, data, config);
};

export const unsubscribeUser = () => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'unsubscribe';
  data.append('access_token', access_token);
  return axios.post(url, data, config);
};

export const deleteImage = (id) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'delete-image';
  data.append('access_token', access_token);
  data.append('image_id', id);
  return axios.post(url, data, config);
};
export const likeImage = (id) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'add-image-to-favourite';
  data.append('access_token', access_token);
  data.append('image_id', id);
  return axios.post(url, data, config);
};
export const dislikeImage = (id) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'remove-image-from-favourite';
  data.append('access_token', access_token);
  data.append('image_id', id);
  return axios.post(url, data, config);
};

export const fetchPricingDetails = () => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'profile-types/list';
  data.append('access_token', access_token);
  return axios.post(url, data, config);
};

export const addToExploreImage = (id) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'add-image-to-explore';
  data.append('access_token', access_token);
  data.append('image_id', id);
  return axios.post(url, data, config);
};

export const fetchUsersLikedImages = () => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'user-images-likes';
  data.append('access_token', access_token);
  return axios.post(url, data, config);
};
export const userLikeImage = (id) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'add-like-to-image';
  data.append('access_token', access_token);
  data.append('image_id', id);
  return axios.post(url, data, config);
};

export const userUnlikeImage = (id) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'remove-like-from-image';
  data.append('access_token', access_token);
  data.append('image_id', id);
  return axios.post(url, data, config);
};

export const variationGenerateImage = (
  styleId,
  prompt,
  negativePrompt,
  qualityValue,
  dimension,
  real_time_generation_status,
  seed,
  scale
) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'generate-images';
  data.append('access_token', access_token);
  data.append('style', styleId);
  data.append('prompt', prompt);
  data.append('negative_prompt', negativePrompt);
  data.append('quality', qualityValue);
  data.append('imageDemension', dimension);
  data.append('real_time_generation_status', real_time_generation_status);
  data.append('seed', seed);
  data.append('prompt_guidance', scale);
  return axios.post(url, data, config);
};

export const inPaintImage = (
  isRTG,
  details,
  originalImage,
  mask,
  originalImgId
) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'inpaint';
  data.append('access_token', access_token);
  data.append('original_image', originalImage);
  data.append('mask_image', mask);
  data.append('prompt', details.prompt);
  data.append('negative_prompt', details.negative_prompt);
  data.append('imageDemension', details.imageDemension);
  data.append('quality', details.quality);
  data.append('original_image_id', originalImgId);
  data.append('real_time_generation_status', isRTG);
  data.append('prompt_guidance', details.prompt_guidance);
  return axios.post(url, data, config);
};
export const rtfInPaintImage = (isRTG, details, originalImage, mask) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'inpaint';
  data.append('access_token', access_token);
  data.append('original_image', originalImage);
  data.append('mask_image', mask);
  data.append('prompt', details.prompt);
  data.append('negative_prompt', details.negative_prompt);
  data.append('imageDemension', details.imageDemension);
  data.append('quality', details.quality);
  data.append('real_time_generation_status', isRTG);
  data.append('prompt_guidance', details.prompt_guidance);
  return axios.post(url, data, config);
};

export const upscaleImage = (details) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'upscale';
  data.append('access_token', access_token);
  data.append('scale', details.scale);
  data.append('image', details.image);
  return axios.post(url, data, config);
};

export const removeBackgroundImage = (details) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'remove-background';
  data.append('access_token', access_token);
  data.append('image', details.image);
  return axios.post(url, data, config);
};
