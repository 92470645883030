import React from 'react';
import Paths from '../../../router/paths';
import { Link } from 'react-router-dom';

const GetUnlimitedBtn = (props) => {
  const { navigateFromSideMenu } = props;
  return (
    <div className="header-link">
      <button
        className="btn btn-primary text-nowrap"
        onClick={() => navigateFromSideMenu('pricing')}
      >
        Get unlimited access
      </button>
    </div>
  );
};
export default GetUnlimitedBtn;
