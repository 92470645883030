import Cookies from 'js-cookie';
import Paths from '../router/paths';

export const getBase64Image = (img) => {
  img.crossOrigin = 'anonymous';
  var canvas = document.createElement('canvas');
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;
  var ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  var dataURL = canvas.toDataURL('image/jpeg');
  return dataURL;
};

export const CalculateValue = (value) => {
  return value;
};
export const ValueLabelFormat = (value) => {
  return value;
};

export const seperateImagensions = (dimension) => {
  return dimension.replace(' ', '').split('x');
};

export const todayDateAndTime = () => {
  let today = new Date();
  let date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  var time =
    today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  return date + ' ' + time;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const toCapitalCaseSpace = (str) => {
  let arr = str.split(' ');
  let res = [];
  for (const item of arr) {
    res.push(
      item.substring(0, 1).toUpperCase() + item.substring(1, item.length)
    );
  }
  res = res.join(' ');
  return res;
};

export const convertToDataURL = async (src) => {
  function toDataURL(src, callback) {
    var image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = function () {
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      context.drawImage(this, 0, 0);
      var dataURL = canvas.toDataURL('image/jpeg');
      callback(dataURL);
    };
    image.src = src;
  }
  return new Promise((resolve, reject) => {
    toDataURL(src, function (dataURL) {
      resolve(dataURL);
    });
  });
};

export const upscaledDimension = (scale, str) => {
  let res = [];
  const newArr = str?.split('x');
  if (newArr) {
    for (const item of newArr) {
      res.push(item * scale);
    }
  }
  let joined = res.join(' x ');
  return joined;
};

export const downloadImage = (url, fileName) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      console.error('Error fetching the image:', error);
    });
};

export const hardReloadToUrl = (route) => {
  if (window.location.host === 'localhost:3000') {
    return window.location.assign(`http://${window.location.host}${route}`);
  } else {
    return window.location.assign(`https://${window.location.host}${route}`);
  }
};

export const handleLogout = () => {
  Cookies.remove('access_token', {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_HOME_URL,
  });
  localStorage.removeItem('savedImagesHistory');
  localStorage.removeItem('selectedTier');
  return hardReloadToUrl(Paths.root);
};
