import axios from 'axios';
import Cookies from 'js-cookie';
var headers = { 'Content-Type': 'multi-part/form-data' };
var config = { headers };
var access_token = Cookies.get('access_token') || '';

export const fetchTeamsList = () => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'teams/get-all';
  data.append('access_token', access_token);
  return axios.post(url, data, config);
};
export const createTeam = (payload) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'teams/create';
  data.append('access_token', access_token);
  for (const payloadKey in payload) {
    data.append(payloadKey, payload[payloadKey]);
  }
  return axios.post(url, data, config);
};

export const addMembersInTeam = (payload) => {
  var data = new FormData();
  var url = process.env.REACT_APP_API_URL + 'teams/members/add';
  data.append('access_token', access_token);
  for (const payloadKey in payload) {
    data.append(payloadKey, payload[payloadKey]);
  }
  return axios.post(url, data, config);
};
