import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SecondaryHeader from '../header/secondary-header';
import SecondaryFooter from '../footer/secondary-footer';
import Paths from '../../router/paths';

const SecondaryLayout = () => {
  const router = useLocation();
  // useEffect(() => {
  //   console.log(router.pathname);
  // }, [router]);
  return (
    <div
      className={`secondary-wrapper ${
        router.pathname === Paths.root ||
        router.pathname === Paths.discover ||
        router.pathname === Paths.signUp ||
        router.pathname === Paths.recentSearches
          ? 'no-padding'
          : ''
      }`}
    >
      <SecondaryHeader />
      <Outlet />
      <SecondaryFooter />
    </div>
  );
};
export default SecondaryLayout;
