import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Paths from '../../../router/paths';

const HeaderExploreMenu = () => {
  return (
    <div className="header-link">
      <Dropdown className="link-dropdown2 no-lines">
        <Dropdown.Toggle id="dropdown-basic">Explore</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href={Paths.discover}>Discover</Dropdown.Item>
          <Dropdown.Item href="#">About us</Dropdown.Item>
          <Dropdown.Item href="https://cascaidrtg.medium.com/">
            Blog
          </Dropdown.Item>
          <Dropdown.Item href={Paths.contactUs}>Contact us</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default HeaderExploreMenu;
