import React, { createContext, useReducer, useEffect } from 'react';
import { GetMyProfile } from '../global/helpers';
import Cookies from 'js-cookie';

const AppReducer = function (state, action) {
  if (typeof action.payload !== 'undefined') {
    switch (action.type) {
      case 'UPDATE_PROFILE_STATE':
        return {
          profile: action.payload,
          active_package: action.payload ? action.payload.active_package : {},
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const initialState = {
  profile: {},
  active_package: {},
};
export const GlobalContext = createContext(initialState);
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const UpdateUserProfile = function () {
    GetMyProfile().then(function (profile) {
      dispatch({ type: 'UPDATE_PROFILE_STATE', payload: profile });
    });
  };
  useEffect(function () {
    var user_authetication_token = Cookies.get('access_token');
    if (
      typeof user_authetication_token != 'undefined' &&
      user_authetication_token.length
    ) {
      UpdateUserProfile();
    }
  }, []);
  return (
    <GlobalContext.Provider
      value={{
        UpdateUserProfile: UpdateUserProfile,
        profile: state.profile,
        active_package: state.active_package,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
