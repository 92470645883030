const Paths = {
  root: '/',
  home: '/home',
  signUp: '/sign-up',
  signIn: '/sign-in',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',
  pricing: '/pricing',
  monthlyPackage: '/monthly-package',
  subscription: '/subscription',
  userProfile: '/user-profile',
  userAccount: '/user-account',
  account: '/account',
  generateImage: '/generate-image',
  subscriptionCanceledConfirm: '/subscription-canceled-confirm',
  upgradePackage: '/upgrade-package',
  logout: '/logout',
  paymentSuccess: '/payment-success',
  paymentFailure: '/payment-failure',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  cancelSubscriptionSuccess: '/cancel-subscription-successful',
  discover: '/discover',
  exploreItem: '/explore/:imageId',
  unsubscribe: '/unsubscribe',
  collections: '/collections',
  collectionItem: '/collections/:collectionId',
  aistock: '/aistock',
  singleImage: '/images/:imageId',
  contactUs: '/contact-us',
  recentSearches: '/recent-activities',
  fileUpload: '/file-upload',
  searchResult: '/search-result',
  rest: '/404',
};

export default Paths;
