import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Paths from '../../router/paths';
import Icons from '../../utils/Icons';

const DiscoverCategory = () => {
  const images = [
    Icons.category_1,
    Icons.category_2,
    Icons.category_3,
    Icons.category_4,
    Icons.category_5,
    Icons.category_6,
  ];

  const imageTitles = [
    'Photorealism',
    'Portrait',
    'Vintage',
    'Photography',
    'Landscape',
    'Animals',
  ];

  return (
    <Fragment>
      <section className="discover-category-box">
        <div className="container">
          <div className="discover-category-head">
            <h4 className="heading28">Your ideas are endless, so are our</h4>
            <h4 className="heading28">
              possibilities. Explore <span>categories</span>
            </h4>
          </div>

          <div className="category-item">
            {images.map((image, index) => (
              <div key={index} className="category-image">
                <p className="category-title">{imageTitles[index]}</p>
                <Link
                  to={`${Paths.discover}?category=${encodeURIComponent(
                    imageTitles[index].toLowerCase()
                  )}`}
                  className="discover-category-item"
                >
                  <img src={image} alt="" />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default DiscoverCategory;
