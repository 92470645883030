import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Paths from '../../router/paths';
import { Image } from 'react-bootstrap';
import Icons from '../../utils/Icons';
import { SendApiRequest } from '../../global/helpers';

const SecondaryFooter = () => {
  const [socialLinks, setSocialLinks] = useState([]);
  useEffect(function () {
    getSocialLinks().then().catch();
  }, []);

  const getSocialLinks = async () => {
    await SendApiRequest('get-social-links', {}).then(function (res) {
      if (res.status) {
        setSocialLinks(res.data);
      }
    });
  };

  const location = useLocation();
  if (location.pathname === Paths.signUp) {
    return null;
  }

  return (
    <footer className="footer-box">
      <div className="footer-wrap">
        <div className="footer-left">
          <Link to={Paths.home}>
            <Image src={Icons.whiteBlueLogo} alt="..." />
          </Link>
        </div>
        <div className="footer-right">
          <div className="terms-and-policy">
            <Link to={Paths.termsOfUse}>Terms of Use</Link>
            <Link to={Paths.privacyPolicy}>Privacy Policy</Link>
          </div>
          <div className="social-wrap">
            {socialLinks.map((item) => (
              <Link to={item?.social_link} key={item.id}>
                <span className={item?.social_icon_url}></span>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="copy-right-wrap">
        <p className="heading12">&copy; Cascaid 2023</p>
      </div>
    </footer>
  );
};
export default SecondaryFooter;
