import React from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { handleLogout, hardReloadToUrl } from '../utils/basic-common';
import Paths from '../router/paths';
const SendApiRequest = async function (url, data) {
  url = process.env.REACT_APP_API_URL + url;
  var user_authetication_token = Cookies.get('access_token');
  var res, headers, result, formdata, config;
  if (
    typeof user_authetication_token != 'undefined' &&
    user_authetication_token.length
  ) {
    try {
      data.access_token = Cookies.get('access_token');
      const formdata = new FormData();
      Object.keys(data).map(function (key) {
        formdata.append(key, data[key]);
        return true;
      });
      headers = { 'Content-Type': 'multi-part/form-data' };
      config = { headers };
      res = await axios.post(url, formdata, config);
      if (res.data) {
        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log('ERROR: ' + error);
      return false;
    }
  } else {
    try {
      formdata = new FormData();
      Object.keys(data).map(function (key) {
        formdata.append(key, data[key]);
        return true;
      });
      headers = { 'Content-Type': 'multi-part/form-data' };
      config = { headers };
      result = await axios.post(url, formdata, config);
      if (result.data) {
        return result.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
};
const DvInnerHTML = function (target, newText) {
  document.querySelectorAll(target).forEach(function (element) {
    element.textContent = newText;
  });
};
const Loader = ({ loading, children }) => {
  if (loading) {
    return <div className="loading-spinner"></div>;
  }
  return children;
};
const OpenInNewTab = function (url) {
  window.open(url, '_blank').focus();
};
const makeId = function (length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
const isHidden = function (el) {
  try {
    var style = window.getComputedStyle(el);
    return style.display === 'none';
  } catch (error) {
    return true;
  }
};

const GetMyProfile = async function () {
  return await SendApiRequest('get-logged-in-user-profile', {}).then(function (
    res
  ) {
    if (res.status) {
      res.data.profile_image =
        res.data.profile_image !== null
          ? res.data.profile_image
          : process.env.REACT_APP_DEFAULT_PROIFLE_IMAGE_URL;
      res.data.cover_image =
        res.data.cover_image !== null
          ? res.data.cover_image
          : process.env.REACT_APP_DEFAULT_PROIFLE_COVER_IMAGE_URL;
      return res.data;
    } else {
      handleLogout();
    }
  });
};
export {
  SendApiRequest,
  DvInnerHTML,
  Loader,
  OpenInNewTab,
  makeId,
  isHidden,
  GetMyProfile,
};
