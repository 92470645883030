import { React, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../contexts/GlobalState';

const DiscoverItem = (props) => {
  const router = useLocation();
  const { data, title, linkText, linkUrl, emptyListComp } = props;
  const { active_package } = useContext(GlobalContext);

  return (
    <section className="discover-box">
      <div className="container">
        {title && (
          <div className="activity-head">
            <div className="discover-head">
              <h4 className="heading28">{title} </h4>
              {linkText && (
                <Link
                  to={linkUrl}
                  className="heading16 has-right-arrow text-uppercase"
                >
                  {linkText} <span className="icon-arrow-right"></span>
                </Link>
              )}
            </div>
          </div>
        )}
        {router.pathname.includes('images') ||
        router.pathname.includes('search-result') ? (
          <div className="discover-wrap">
            {data.length > 0
              ? data.map(
                  (item, index) =>
                    item?.image_url &&
                    item?._id && (
                      <div key={index} className="discover-col">
                        <Link
                          to={`/images/${item?._id}`}
                          className="discover-item"
                        >
                          <img
                            src={
                              active_package
                                ? item?.image_url
                                : `${item?.image_url}?tr=n-watermark-img`
                            }
                            alt="..."
                            className="fit-cover"
                          />
                        </Link>
                      </div>
                    )
                )
              : emptyListComp}
          </div>
        ) : (
          <div className="discover-wrap">
            {data.length > 0
              ? data.map((item) =>
                  item.image && item.image.image_url ? (
                    <div key={item._id} className="discover-col">
                      <Link
                        to={`/images/${item.image._id}`}
                        className="discover-item"
                      >
                        <img
                          src={
                            active_package
                              ? item.image.image_url
                              : `${item.image.image_url}?tr=n-watermark-img`
                          }
                          alt="..."
                          className="fit-cover"
                        />
                      </Link>
                    </div>
                  ) : null
                )
              : emptyListComp}
          </div>
        )}
      </div>
    </section>
  );
};
export default DiscoverItem;
