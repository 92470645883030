import React from 'react';
import { Link } from 'react-router-dom';
import Paths from '../../router/paths';

const NoActivity = (props) => {
  const { title, icon } = props;
  return (
    <div className="no-items-found-wrap">
      <div className="mx-588">
        <span className={`list-icon ${icon}`}></span>
        <p className="heading28 montserrat-reg">{title} </p>
        <div className="btn-controls">
          {icon === 'icon-file-download' && (
            <Link to={Paths.pricing} className="btn btn-primary montserrat-sb">
              Get Unlimited downloads
            </Link>
          )}
          <Link
            to={Paths.discover}
            className="btn btn-outline-light montserrat-sb"
          >
            Discover images
          </Link>
        </div>
      </div>
    </div>
  );
};
export default NoActivity;
