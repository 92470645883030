import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Cookies from 'js-cookie';
import { GlobalContext } from '../../contexts/GlobalState';
import Dropdown from 'react-bootstrap/Dropdown';
import Paths from '../../router/paths';
import { ReferralModal } from '../modals/ReferralModal';

import { useTranslation } from 'react-i18next';
import { handleLogout } from '../../utils/basic-common';

const Header = () => {
  const { profile } = useContext(GlobalContext);
  const { t, i18n } = useTranslation();

  const [accessToken, setAccessToken] = useState(false);
  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);

  const tokenCount =
    profile.current_daily_count + (profile.referral_bonus_count || 0);

  const router = useLocation();
  useEffect(function () {
    var cookie = Cookies.get('access_token');
    if (typeof cookie !== 'undefined' && cookie.length) {
      setAccessToken(cookie);
    }
  }, []);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const handleGeneratePage = () => {
    if (profile?.is_subscribed_to_package == false) {
      window.location.assign(process.env.REACT_APP_URL + Paths.pricing);
    } else {
      window.location.assign(process.env.REACT_APP_URL + Paths.generateImage);
    }
  };

  return (
    /*${router.pathname === '/' ? 'no-background':''}*/
    <header className="header fixed-top">
      <Container fluid>
        <Navbar collapseOnSelect expand="lg" variant="dark">
          <Navbar.Brand>
            <Link to={`${process.env.REACT_APP_URL}/`}>
              <img
                src={`${process.env.REACT_APP_URL}/assets/images/logo-black.svg`}
                alt="Logo"
              />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <span className="icon-menu"></span>
          </Navbar.Toggle>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              {/* <Link className="nav-link" to="#features">How It Works</Link> */}

              {/* <Link className="nav-link" to="#CommunityShowcase">Community Showcase</Link> */}

              {/* <NavDropdown title="Help" id="Help_dropdown" className="help-nav-item">
                                <NavDropdown.Item href="#action/3.1" className="help-dropdown-menu bg-c-dark">
                                    <div className="text-wrap">
                                        <h3>Need Help?</h3>
                                        <h6>Contact our Support channel on Discord</h6>
                                        <img src={`${process.env.REACT_APP_URL}/assets/images/DiscordLogo.png`} alt="DiscordLogo" />
                                    </div>
                                </NavDropdown.Item>
                            </NavDropdown> */}

              {/* <Link className="nav-link" to={`${process.env.REACT_APP_URL}/package`}>Pricing</Link> */}
              <Link className="nav-link-dark" to={Paths.discover}>
                {t('header.explore')}
              </Link>
              <Link className="nav-link-dark" to={Paths.pricing}>
                {t('header.pricing')}
              </Link>
              {/*<Dropdown className="profile-dropdown language-changer-wrap">
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  {i18n.language == 'kr' && '한국인'}
                  {i18n.language == 'en' && 'English'}
                </Dropdown.Toggle>

                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    as="button"
                    onClick={() => changeLanguage('en')}
                  >
                    English
                  </Dropdown.Item>
                   <Dropdown.Item
                    as="button"
                    onClick={() => changeLanguage('kr')}
                  >
                    한국인
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>*/}

              {accessToken ? (
                <>
                  {!(router.pathname === '/generate-image') && (
                    <button
                      className="nav-link generate-btn"
                      onClick={handleGeneratePage}
                    >
                      {t('header.generate')}
                    </button>
                  )}
                  {/*<NavDropdown title={<img src={"https://ui-avatars.com/api/?name=" + profile.username}
                                                         alt="Profile" width={"50px"}/>} id="Profile_dropdown"
                                             className="nav-item profile-nav-item">
                                    <div className="profile-menu-items profile-img-li">
                                        <Link to={`${process.env.REACT_APP_URL}/user-profile`}>Profile</Link>
                                        <Link to={`${process.env.REACT_APP_URL}/user-account`}>Account</Link>
                                    </div>
                                </NavDropdown>*/}
                  <Dropdown className="profile-dropdown">
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <img
                        src={
                          'https://ui-avatars.com/api/?name=' + profile.username
                        }
                        alt="Profile"
                        width={'50px'}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="end">
                      <div className="tokens-wrap">
                        <span className="token-count">{tokenCount}</span>
                        <p>
                          {t('header.tokens')} <br /> {t('header.remaining')}
                        </p>
                      </div>
                      <NavLink
                        className={`profile-link ${
                          router.pathname === Paths.userProfile
                            ? 'selected'
                            : ''
                        }`}
                        to={process.env.REACT_APP_URL + Paths.userProfile}
                      >
                        {t('header.myGallery')}
                      </NavLink>
                      <div
                        className={`profile-link ${
                          isReferralModalOpen && 'selected'
                        }`}
                        onClick={() => setIsReferralModalOpen(true)}
                      >
                        Referrals
                      </div>
                      {/* <NavLink className={`profile-link ${router.pathname === '/user-account' ? 'selected':''}`} to={`${process.env.REACT_APP_URL}/user-account`}>Account</NavLink> */}
                      {profile?.profile_type_name
                        ?.toLowerCase()
                        .includes('explorer') ? (
                        <NavLink
                          className="profile-link"
                          to="https://billing.stripe.com/p/login/28oeYb8Xu3vXeuk3cc"
                          target="_blank"
                        >
                          {t('header.subscription')}
                        </NavLink>
                      ) : (
                        <NavLink className="profile-link" to={Paths.pricing}>
                          {t('header.subscription')}
                        </NavLink>
                      )}
                      <button className="profile-link" onClick={handleLogout}>
                        {t('header.logOut')}
                      </button>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <>
                  <Link
                    className="nav-link sign-in-btn"
                    to={process.env.REACT_APP_URL + Paths.signIn}
                  >
                    <img
                      src={`${process.env.REACT_APP_URL}/assets/images/User.png`}
                      alt=""
                    />{' '}
                    {t('header.signIn')}
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
      <ReferralModal
        isReferralModalOpen={isReferralModalOpen}
        handleClose={() => setIsReferralModalOpen(false)}
        referralCode={profile.id}
      />
    </header>
  );
};

export default Header;
