import React from 'react';
import Icons from '../../utils/Icons';
import { Link } from 'react-router-dom';

const HomepageItem = (props) => {
  const { linkText, linkUrl } = props;

  const images = [
    Icons.discoverItem_1,
    Icons.discoverItem_2,
    Icons.discoverItem_3,
    Icons.discoverItem_4,
    Icons.discoverItem_5,
    Icons.discoverItem_6,
    Icons.discoverItem_7,
    Icons.discoverItem_8,
  ];

  const imageIds = [
    '65a180268d74d1c4d7dfccca',
    '65833d268ac40d95e3c85fce',
    '659ecc8c8d74d1c4d7dfbb0a',
    '65b43aab8d74d1c4d7e029e9',
    '65b3f3f88d74d1c4d7e01de2',
    '65aebca18d74d1c4d7dffd5f',
    '655d37344d9dce30bcbffe1e',
    '65aaa2778d74d1c4d7dfe768',
  ];

  return (
    <section className="discover-box">
      <div className="container">
        <div className="discover-head">
          <h4 className="heading28">
            Discover <span>awesome</span> images
          </h4>
          {linkText && (
            <Link
              to={linkUrl}
              className="heading16 has-right-arrow text-uppercase"
            >
              {linkText} <span className="icon-arrow-right"></span>
            </Link>
          )}
        </div>

        <div className="homepage-item">
          {images.map((image, index) => (
            <div key={index} className="homepage-image">
              <Link to={`/images/${imageIds[index]}`}>
                {' '}
                {/* Update this line */}
                <img src={image} alt="" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomepageItem;
